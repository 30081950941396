export enum RolesDBEvent {
  ROLES_DB_ROLE_CLICK = "roles_db_role_click",
  ROLES_DB_SORT = "roles_db_sort",
  ROLES_DB_SEARCH_QUERY = "roles_db_search_query",
}

export enum RoleViewEvent {
  ROLE_VIEW_SORT = "role_view_sort",
  ROLE_VIEW_SEARCH_QUERY = "role_view_search_query",
  ROLE_VIEW_SEND_ROLE = "role_view_send_role",
  BOOKMARK = "bookmark",
  BOOKMARK_REMOVE = "bookmark_remove",
  UNMATCH = "unmatch",
  UNMATCH_REMOVE = "unmatch_remove",
  REQUEST_FEEDBACK = "request_feedback",
}

export enum LoginEvent {
  LOGIN = "login",
  LOGOUT = "logout",
}

export enum FailureEvent {
  FAILURE = "failure",
}

export enum AlumProfileEvent {
  ALUM_PROFILE_LINKEDIN = "alum_profile_linkedin",
  ALUM_PROFILE_ROLE_MATCH = "alum_profile_role_match",
  ALUM_PROFILE_SEND_ROLE = "alum_profile_send_role",
  ALUM_PROFILE_SEND_MESSAGE = "alum_profile_send_message",
  ALUM_PROFILE_HISTORY_SEE_ALL = "alum_profile_history_see_all",
  ALUM_PROFILE_CONTACTS_SEE_ALL = "alum_profile_contacts_see_all",
  ALUM_PROFILE_EDUCATION_SEE_ALL = "alum_profile_education_see_all",
}

export enum NoteEvent {
  NOTES_NEW = "notes_new",
  NOTES_DELETED = "notes_deleted",
  NOTES_VIEW = "notes_view",
  NOTES_SHOW_ALL = "notes_show_all",
}

export enum SendRoleEvent {
  SEND_ROLE_DRAFT = "send_role_draft",
  SEND_ROLE_PREVIEW = "send_role_preview",
  SEND_ROLE_WINDOW = "send_role_window",
  SEND_ROLE_SELECT_ROLE = "send_role_select_role",
}

export enum ReferralRequestEvent {
  REFERRAL_REQUEST_DRAFT = "referral_request_draft",
  REFERRAL_REQUEST_PREVIEW = "referral_request_preview",
  REFERRAL_REQUEST_WINDOW = "referral_request_window",
  REFERRAL_REQUEST_SELECT_ROLE = "referral_request_select_role",
}

export enum SendMessageEvent {
  SEND_MESSAGE_DRAFT = "send_message_draft",
  SEND_MESSAGE_PREVIEW = "send_message_preview",
  SEND_MESSAGE_WINDOW = "send_message_window",
}

export enum AlumDBEvent {
  ALUM_DB_SEND_ROLE = "alum_db_send_role",
  ALUM_DB_REQUEST_REFERRAL = "alum_db_request_referral",
  ALUM_DB_SEND_MESSAGE = "alum_db_send_message",
  ALUM_DB_SORT = "alum_db_sort",
  ALUM_DB_SEARCH_QUERY = "alum_db_search_query",
  ALUM_DB_ALUM_CLICK = "alum_db_alum_click",
  ALUM_DB_FILTER = "alum_db_filter",
  FAVORITE = "favorite",
  FAVORITE_REMOVE = "favorite_remove",
  ARCHIVE = "archive",
  ARCHIVE_REMOVE = "archive_remove",
}

export enum SendEmailAddRoleEvent {
  SEND_EMAIL_ADD_ROLE_SEARCH_QUERY = "send_email_add_role_search_query",
}

export enum RoleMatchingCriteriaEvent {
  NEW_CRITERIA_CLICK = "rmc_new_criteria_click",
  DELETE_CRITERIA_CLICK = "rmc_delete_criteria_click",
  EDIT_CRITERIA_CLICK = "rmc_edit_criteria_click",
  NEW_CRITERIA_SAVE = "rmc_new_criteria_save",
  DELETE_CRITERIA_SAVE = "rmc_delete_criteria_save",
  EDIT_CRITERIA_SAVE = "rmc_edit_criteria_save",
  USE_ALL_ROLES = "rmc_use_all_roles",
  USE_SPECIFIC_ROLES = "rmc_use_specific_roles",
  FAVOR_HIGHER_TENURE = "rmc_favor_higher_tenure",
  FAVOR_LOWER_TENURE = "rmc_favor_lower_tenure",
  DISREGARD_TENURE = "rmc_do_not_rank_on_tenure",
  APPLY_TO_NEW_AND_EXISTING_ROLES = "rmc_apply_to_new_and_existing_roles",
  APPLY_TO_ONLY_NEW_ROLES = "rmc_apply_to_only_new_roles",
  IS_AUTOMATED = "rmc_is_automated",
  IS_MANUAL = "rmc_is_manual",
  IS_ENABLED = "rmc_is_enabled",
  IS_DISABLED = "rmc_is_disabled",
  TIME_SINCE_DEPARTURE_CHANGED = "rmc_time_since_departure_changed",
  PROXIMITY_TO_ROLE_CHANGED = "rmc_proximity_to_role_changed",
  NAME_CHANGED = "rmc_name_changed",
  DEPARTMENT_CHANGED = "rmc_department_changed",
  ROLE_CHANGED = "rmc_role_changed",
  IS_ENABLED_FROM_TOOGLE = "rmc_is_enabled_from_toggle",
  IS_DISABLED_FROM_TOGGLE = "rmc_is_disabled_from_toggle",
}

export enum RoleMatchingEmailSettingsEvent {
  EMAIL_CADENCE_CHANGED_TO_WEEKLY = "rm_email_cadence_changed_to_weekly",
  EMAIL_CADENCE_CHANGED_TO_MONTHLY = "rm_email_cadence_changed_to_monthly",
  EMAIL_CADENCE_CHANGES_SAVED = "rm_email_cadence_changes_saved",
}

export type GAEvent =
  | RolesDBEvent
  | RoleViewEvent
  | LoginEvent
  | FailureEvent
  | AlumProfileEvent
  | NoteEvent
  | SendRoleEvent
  | ReferralRequestEvent
  | SendMessageEvent
  | SendEmailAddRoleEvent
  | AlumDBEvent
  | RoleMatchingCriteriaEvent
  | RoleMatchingEmailSettingsEvent;
